<template lang="">
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('subscription.register-title') }}</h3>
        <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
      </div>
      <!--/title-sort-box-->
      <article class="board-view">
        <div class="alarm-rule-wrapper">
          <ValidationObserver ref="form">
            <form enctype="multipart/form-data">
              <table class="alarm-rule-table">
                <colgroup>
                  <col style="width:15%" />
                  <col style="width:32%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-endDate')
                      }}</label>
                    </th>
                    <td>
                      <date-picker
                        v-model="formData.endDate"
                        mode="dateTime"
                        :min-date="new Date()"
                        :masks="{ inputDateTime: 'YYYY/MM/DD HH:mm:ss' }"
                      >
                        <template #default="{ inputValue, togglePopover}">
                          <span class="daterange-wrap" style="width: 40%">
                            <text-input
                              rules="required"
                              label="endDate"
                              name="endDate"
                              maxlength="20"
                              :value="inputValue"
                              @click="togglePopover"
                              placeholder="yyyy/mm/dd hh:MM:ss"
                              :inputStyle="
                                new Date(formData.endDate) < new Date()
                                  ? 'color:red'
                                  : 'color:black'
                              "
                            />
                            <label
                              for="endDate"
                              @click="togglePopover"
                              class="ic-calendar"
                            ></label>
                          </span>
                        </template>
                      </date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-reason')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        rules="required"
                        label="reason"
                        name="reason"
                        maxlength="100"
                        v-model.trim="formData.reason"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-criteriaGroup')
                      }}</label>
                    </th>
                    <td>
                      <table class="alarm-rule-dtl-table">
                        <colgroup>
                          <col style="width:18%" />
                          <col style="width:20%" />
                          <col style="width:18%" />
                          <col style="width:34%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th style="text-align: left;">
                              <label class="ic-star table-label">{{
                                $t('subscription.lable-resourceType')
                              }}</label>
                            </th>
                            <td>
                              <select
                                class="isf term-sel"
                                name="resourceType"
                                v-model="formData.resourceType"
                                style="width: 130px;"
                                @change="setCriteria"
                              >
                                <option value="Observation">Observation</option>
                                <option value="Patient">Patient</option>
                              </select>
                            </td>
                            <th>
                              <label class="ic-star table-label">{{
                                $t('subscription.lable-criteriaQuery')
                              }}</label>
                            </th>
                            <td>
                              <text-input
                                rules="required"
                                label="criteriaQuery"
                                name="criteriaQuery"
                                inputStyle="width: 105%"
                                maxlength="255"
                                v-model.trim="formData.criteriaQuery"
                                @input="setCriteria"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th style="text-align: left; margin-left: 10px;">
                              <label class="ic-star table-label">{{
                                $t('subscription.lable-criteria')
                              }}</label>
                            </th>
                            <td colspan="3">
                              <text-input
                                rules="required"
                                label="criteria"
                                name="criteria"
                                v-model.trim="formData.criteria"
                                inputStyle="width: 102%; background: #eee; color: #aaa; border-color: #eee;"
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-endpoint')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                        }"
                        :errorData="urlInputErr"
                        label="channelEndpoint"
                        name="channelEndpoint"
                        inputStyle="width: 100%"
                        maxlength="255"
                        v-model.trim="formData.channelEndpoint"
                        :placeholder="urlPlaceholder"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-payload')
                      }}</label>
                    </th>
                    <td>
                      <select
                        class="isf term-sel"
                        name="channelPayload"
                        style="width: 35%;"
                        v-model="formData.channelPayload"
                      >
                        <option value="application/json"
                          >application/json</option
                        >
                        <option value="application/xml">application/xml</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">{{
                        $t('subscription.lable-status')
                      }}</label>
                    </th>
                    <td>
                      <button
                        type="button"
                        class="bt-md bt-default"
                        style="color: white; vertical-align: top; width: 75px;"
                        :style="
                          formData.status
                            ? 'background: blue'
                            : 'background: red'
                        "
                        @click="toggleStatus"
                      >
                        <span>{{ formData.status ? 'ON' : 'OFF' }}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </ValidationObserver>
        </div>
        <div class="btns-bottom">
          <!-- insert type -->
          <button
            type="button"
            class="bt-md bt-default"
            @click="goBack"
            v-if="type === 'insert'"
          >
            <span>{{ $t('common.btn-cancel') }}</span>
          </button>
          <button
            type="button"
            class="bt-md bt-red"
            @click="checkValidate"
            v-if="type === 'insert'"
          >
            <span>{{ $t('common.btn-submit') }}</span>
          </button>
          <!-- update type -->
          <button
            type="button"
            class="bt-md bt-default"
            @click="goBack"
            v-if="type === 'update'"
          >
            <span>{{ $t('common.btn-back') }}</span>
          </button>
          <button
            type="button"
            class="bt-md bt-blue"
            v-if="type === 'update'"
            @click="checkValidate"
          >
            <span>{{ $t('common.btn-update') }}</span>
          </button>
          <button
            type="button"
            class="bt-md bt-red"
            v-if="type === 'update'"
            @click="deleteSubscription"
          >
            <span>{{ $t('common.btn-delete') }}</span>
          </button>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput';
import {
  fetchSubscriptionDetail,
  insertSubscription,
  updateSubscription,
  deleteSubscription,
} from '@/api/subscriptionApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  mixins: [CommonMixins],
  components: { TextInput },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.subscription'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      urlPlaceholder: this.$i18n.t('common.placeholder.url-format'),
      urlInputErr: {
        message: this.$i18n.t('subscription.input-warn-url-format'),
      },
      formData: {
        endDate: '',
        reason: '',
        resourceType: 'Observation',
        criteriaQuery: '',
        criteria: '',
        channelEndpoint: '',
        channelPayload: 'application/json',
        status: true,
      },
      srvcId: '',
    };
  },
  created() {
    this.srvcId = sessionStorage.getItem('srvcId');

    if (this.type === 'update') {
      this.fetchSubscriptionDetail();
    } else {
      this.setCriteria();
    }
  },
  methods: {
    // 구독 상세 정보 조회
    async fetchSubscriptionDetail() {
      this.$dialogs.loading();
      const { resourceId } = this.$route.params;
      const { data } = await fetchSubscriptionDetail(resourceId).catch(() => {
        this.$dialogs.close();
      });
      this.formData = data;
      this.formData.status = data.status === 'active' ? true : false;

      let criteriaArr = this.formData.criteria.split(
        `?identifier=${this.srvcId}%7C&`,
      );

      this.formData.resourceType = criteriaArr[0];
      this.formData.criteriaQuery = criteriaArr[1];

      this.$dialogs.close();
    },

    // criteria set
    setCriteria() {
      const resourceType = this.formData.resourceType;
      const criteriaQuery =
        this.formData.criteriaQuery != null && this.formData.criteriaQuery != ''
          ? `&${this.formData.criteriaQuery}`
          : '';
      this.formData.criteria = `${resourceType}?identifier=${this.srvcId}%7C${criteriaQuery}`;
    },

    // 상태 버튼 토글
    toggleStatus() {
      this.formData.status = !this.formData.status;
    },

    // 페이지 유효성 검사
    checkValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
          return;
        }

        // endDate check
        if (this.formData.endDate < new Date()) {
          this.$dialogs.alert(this.$i18n.t('subscription.input-warn-endDate'));
          return;
        }

        this.setFormData();
      });
    },

    // 등록/수정을 위한 formData set
    setFormData() {
      const endDate = CommonMixins.methods.common_getDateString(
        this.formData.endDate,
      );
      const reason = this.formData.reason;
      const criteria = this.formData.criteria;
      const channelEndpoint = this.formData.channelEndpoint;
      const channelPayload = this.formData.channelPayload;
      const status = this.formData.status ? 'active' : 'off';

      let formData = {
        endDate,
        reason,
        criteria,
        channelEndpoint,
        channelPayload,
        status,
      };

      if (this.type === 'update') {
        this.updateSubscription(formData);
      } else {
        this.insertSubscription(formData);
      }
    },

    // 구독 등록
    async insertSubscription(formData) {
      // 등록
      const {
        data: { valid },
      } = await insertSubscription(formData);

      if (valid) {
        this.$dialogs
          .alert(this.$i18n.t('common.alert-submit-success'))
          .then(res => {
            if (res) {
              this.resetForm();
              this.$router.push({ name: 'subscription' });
            }
          });
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-fail'));
      }
    },

    // 구독 수정
    async updateSubscription(formData) {
      // 수정할 subscription의 rsource id 추가
      const { resourceId } = this.$route.params;
      formData = { ...formData, resourceId };

      // 수정
      const {
        data: { valid },
      } = await updateSubscription(formData);

      if (valid) {
        this.$dialogs
          .alert(this.$i18n.t('common.alert-update-success'))
          .then(res => {
            if (res) {
              this.resetForm();
              this.$router.push({ name: 'subscription' });
            }
          });
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-update-fail'));
      }
    },

    // 구독 삭제
    async deleteSubscription() {
      // 삭제할 subscription의 rsource id
      const { resourceId } = this.$route.params;

      // 삭제
      const {
        data: { valid },
      } = await deleteSubscription(resourceId);

      if (valid) {
        this.$dialogs
          .alert(this.$i18n.t('common.alert-delete-success'))
          .then(res => {
            if (res) {
              this.resetForm();
              this.$router.push({ name: 'subscription' });
            }
          });
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-delete-fail'));
      }
    },

    // 폼 초기화
    resetForm() {
      this.$refs.form.reset();
      // i18n 적용 이후 에러 발생하여 주석 처리
      // Object.assign(this.$data, this.$options.data());
    },

    // 폼 초기화 후 목록 이동
    goBack() {
      this.resetForm();
      const { page } = this.$route.params;
      this.$router.push({ name: 'subscription', params: { page } });
    },
  },
};
</script>
<style scoped>
@import url('./subscription.css');
</style>
